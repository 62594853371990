import isUrl from 'is-url';

const getRequiredValidation = (
  requiredValidationMessage: string,
) => ({
  required: requiredValidationMessage,
});

const getAtLeastOneElementRequiredValidation = (
  requiredValidationMessage: string,
) => ({
  validate: (value: string) => (
    value.length > 0 || requiredValidationMessage
  ),
});

const getEmailValidation = (
  emailValidationMessage: string,
  requiredValidationMessage: string,
) => ({
  required: requiredValidationMessage,
  pattern: {
    message: emailValidationMessage,
    // eslint-disable-next-line max-len
    value: /^([\w!#$%&'*+./=?^`{|}~-]+@[\dA-Za-z](?:[\dA-Za-z-]{0,61}[\dA-Za-z])?(?:\.[\dA-Za-z](?:[\dA-Za-z-]{0,61}[\dA-Za-z])?)*)?$/,
  },
});

const getPasswordValidation = (
  passwordTooShortMessage: string,
  requiredValidationMessage: string,
) => ({
  required: requiredValidationMessage,
  minLength: {
    value: 12,
    message: passwordTooShortMessage,
  },
});

const getUrlValidation = (
  urlValidationMessage: string,
  requiredValidationMessage: string,
  allowRelative = false,
) => ({
  required: requiredValidationMessage,
  validate: (value: string) => (
    !value
    || isUrl(value)
    || (allowRelative && value.startsWith('/'))
    || urlValidationMessage
  ),
});

const getApplyUrlValidation = (
  urlValidationMessage: string,
  requiredValidationMessage: string,
  showApplyUrl: boolean,
) => ({
  validate: (value: string) => (value
    ? isUrl(value) || urlValidationMessage
    : !showApplyUrl || requiredValidationMessage
  ),
});

const getSlugValidation = () => ({
  pattern: {
    message: 'Slugs must use only lowercase characters, number and hyphens',
    value: /^[\da-z-]+$/,
  },
});

export {
  getRequiredValidation,
  getAtLeastOneElementRequiredValidation,
  getEmailValidation,
  getPasswordValidation,
  getUrlValidation,
  getApplyUrlValidation,
  getSlugValidation,
};
