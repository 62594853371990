import React from 'react';
import { useIntl } from 'react-intl';
import Subtitle2 from '@fuww/library/src/Typography/SubTitle2';
import messages from '../../lib/messages.mjs';
import { useSiteContext } from '../SiteContext';
import LinkAndAnchor from '../LinkAndAnchor';
import getOriginByLocale from '../../lib/getOriginByLocale';
import constructUrl from '../../lib/constructUrl';

const Disclaimer = () => {
  const intl = useIntl();
  const { dashboard, origin, locale } = useSiteContext();

  return (
    <>
      <LinkAndAnchor
        origin={getOriginByLocale(origin, locale)}
        href={constructUrl('/privacy', origin, dashboard)}
        styled
      >
        <Subtitle2
          tag="span"
        >
          { intl.formatMessage(messages.privacy) }
        </Subtitle2>
      </LinkAndAnchor>
      <LinkAndAnchor
        origin={getOriginByLocale(origin, locale)}
        href={constructUrl('/terms-of-use', origin, dashboard)}
        styled
      >
        <Subtitle2
          tag="span"
        >
          { intl.formatMessage(messages.termsOfUse) }
        </Subtitle2>
      </LinkAndAnchor>
    </>
  );
};

export default Disclaimer;
