import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import { Grid, GridCell } from '../Grid';
import { Breadcrumbs } from '../Breadcrumb';
import LogoFashionUnited from '../Icons/FashionUnited';
import Subtitle2 from '../Typography/SubTitle2';
import Headline5 from '../Typography/Heading/Headline5';
import breakpoints from '../Theme/breakpoints';
import { mediumRoundedCorners } from '../RoundedCorners';

const Layout = styled.div`
  &&  {
    background-color: var(--white);
    padding: 16px 12px 32px;
    margin: 0 auto;
    border: none;
    ${mediumRoundedCorners};

    @media (min-width: ${breakpoints.sm}px) {
      padding: 32px 16px;
      margin-bottom: 32px;
      max-width: 375px;
      border: 1px solid var(--black-disabled);
    }

    > svg {
      margin: 0 auto 16px;
      display: none;

      @media (min-width: ${breakpoints.sm}px) {
        display: block;
      }
    }

    .MuiTextField-root {
      margin: 20px 0 0;
      width: 100%;
    }

    .mdc-button {
      width: 100%;
      margin: 20px 0 0;
    }
  }
`;

const Privacy = styled.div`
  display: flex;
  justify-content: flex-end;

  .MuiTypography-subtitle2 {
    margin: 0 0 0 16px;
  }
`;

const StyledHeadline5 = styled(Headline5)`
  && {
    color: var(--black-medium);
    text-align: center;
    margin-bottom: 16px;
  }
`;

const InfoPanel = styled.div`
  font-family: var(--font-primary);
  max-width: 600px;
  margin: 0 auto;
  padding: 0 32px;
  text-align: left;

  @media (min-width: ${breakpoints.sm}px) {
    padding: 32px;
  }

  span {
    font-size: var(--size-h5);
    font-family: var(--font-secondary);
  }

  ul {
    margin: 16px auto 0;
    max-width: 600px;
    padding: 0 0 0 1.2rem;
  }

  li {
    text-align: left;
    padding-bottom: 4px;
  }
`;

const RedirectLink = styled.span`
  margin: 0 auto;
  padding: 32px 0 16px;
  display: block;
  text-align: center;
  font-family: var(--font-primary);

  @media (min-width: ${breakpoints.sm}px) {
    padding: 0 0 16px;
  }
`;

const loginSignupLayoutPropertyTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  disclaimer: PropTypes.node.isRequired,
  infoPanel: PropTypes.node,
  redirectLink: PropTypes.node,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.node,
  ).isRequired,
};

type LoginSignupLayoutProperties = InferProps<
typeof loginSignupLayoutPropertyTypes
>;

const LoginSignupLayout = ({
  title, breadcrumbs, children, disclaimer, infoPanel, redirectLink,
}: LoginSignupLayoutProperties) => (

  <Grid container>
    <GridCell padding="0 0 20px 20px">
      <Breadcrumbs crumbs={breadcrumbs} />
    </GridCell>
    <GridCell padding="4px 0">
      {infoPanel && (
        <InfoPanel>
          {infoPanel}
        </InfoPanel>
      )}
      {redirectLink && (
        <RedirectLink>
          {redirectLink}
        </RedirectLink>
      )}
      <Layout>
        <LogoFashionUnited width="168" height="18" />
        <StyledHeadline5 tag="h1">
          {title}
        </StyledHeadline5>
        {children}
        <Privacy>
          {disclaimer}
        </Privacy>
      </Layout>
    </GridCell>
  </Grid>
);

LoginSignupLayout.propTypes = loginSignupLayoutPropertyTypes;

const Container = styled.div`
  display: flex;
  margin: 0 0 24px;

  .MuiTypography-subtitle2 {
    margin: 0 12px 0 0;
  }

  a {
    min-width: 48px;
    height: 48px;
  }
`;

const subtitlePropertyTypes = {
  anchor: PropTypes.node,
  prefix: PropTypes.string,
};

type SubtitleProperties = InferProps<
typeof subtitlePropertyTypes
>;

const Subtitle = ({ anchor, prefix }: SubtitleProperties) => (
  <Container>
    {prefix && <Subtitle2 tag="span">{prefix}</Subtitle2>}
    {anchor}
  </Container>
);

Subtitle.propTypes = subtitlePropertyTypes;

export { LoginSignupLayout, Subtitle };
